<template>
  <!-- Sidebar -->
  <div
    class="navbar navbar-inverse fixed-top"
    id="sidebar-wrapper"
    role="navigation"
    aria-label="Sidebar navigation"
    :style="{ background: style.menuBackgroundColor, color: style.menuTextColor }"
    v-on-clickaway="closeSidebar"
    v-if="company && sidebarShown"
    @keydown.esc="closeSidebar"
  >
    <ul class="nav sidebar-nav">
      <template v-for="item in menu">
        <li class="text-left" :class="{ dropdown: item.hasDropdown }" :key="item.id">
          <a
            class="pointer"
            v-if="item.hasDropdown"
            :href="!item.hasDropdown && 'javascript:void(0)'"
            @click="toggleMenuDropdown(item)"
            :style="{ color: style.menuTextColor }"
          >
            <button
              :aria-label="item.title"
              class="material-icons float-right submenu-link pointer"
              :style="{ color: style.menuTextColor }"
              v-if="item.hasDropdown"
              :aria-expanded="String(item.opened)"
              :aria-haspopup="item.hasDropdown ? true : false"
            >
              <span v-if="!item.opened">keyboard_arrow_down</span>
              <span v-else>keyboard_arrow_up</span>
            </button>
            <i class="material-icons" :style="{ color: style.menuTextColor }">{{ item.icon }}</i>
            {{ item.title }}
          </a>
          <a
            v-else
            class="pointer text-left"
            href="javascript:void(0)"
            @click="navigate(item)"
            :style="{ color: style.menuTextColor }"
          >
            <i class="material-icons" :style="{ color: style.menuTextColor }">{{ item.icon }}</i>
            {{ item.title }}
          </a>
        </li>
        <template v-for="subItem in item.items">
          <li
            class="text-left"
            v-if="item.opened"
            :key="'dropdown-' + subItem.id"
            @click="toggleSubMenuDropdown(item, subItem)"
            :class="{ disabled: subItem.disabled }"
          >
            <template v-if="!subItem.navigate">
              <a class="pointer sub-menu" :style="{ color: style.menuTextColor }">
                <i class="material-icons" v-if="subItem.items && subItem.items.length"></i>
                {{ subItem.title }}
                <i
                  class="material-icons sub-icon float-right"
                  v-if="subItem.items && subItem.items.length"
                  :style="{ color: style.menuTextColor }"
                >
                  <span v-if="!subItem.opened">keyboard_arrow_down</span> <span v-else>keyboard_arrow_up</span></i
                >
              </a>
            </template>
            <router-link
              v-if="subItem.navigate"
              :to="subItem.link"
              class="pointer sub-menu"
              @click.native="closeSidebar"
              :style="{ color: style.menuTextColor }"
              >{{ subItem.title }}</router-link
            >
          </li>
          <template v-if="subItem.opened && subItem.items && subItem.items.length">
            <!-- Link to open LP level page -->
            <li :key="subItem.id" class="text-left">
              <router-link
                :to="subItem.link"
                class="pointer second-sub-menu"
                @click.native="closeSidebar"
                :style="{ color: style.menuTextColor }"
                >> {{ getLearningPageLevelTitle(subItem.title) }}</router-link
              >
            </li>

            <template v-for="subSubItem in subItem.items">
              <li
                class="text-left"
                v-if="item.opened"
                :key="'dropdown-' + subSubItem.id"
                @click="toggleSubSubMenuDropdown(item, subItem, subSubItem)"
                :class="{ disabled: subSubItem.disabled }"
              >
                <template v-if="!subSubItem.navigate">
                  <a class="pointer second-sub-menu" :style="{ color: style.menuTextColor }">
                    <i class="material-icons" v-if="subSubItem.items && subSubItem.items.length"></i>
                    {{ subSubItem.title }}
                    <i
                      class="material-icons sub-icon float-right"
                      v-if="subSubItem.items && subSubItem.items.length"
                      :style="{ color: style.menuTextColor }"
                    >
                      <span v-if="!subSubItem.opened">keyboard_arrow_down</span>
                      <span v-else>keyboard_arrow_up</span></i
                    >
                  </a>
                </template>
                <router-link
                  v-if="subSubItem.navigate"
                  :to="subSubItem.link"
                  class="pointer second-sub-menu"
                  @click.native="closeSidebar"
                  :style="{ color: style.menuTextColor }"
                  >{{ subSubItem.title }}</router-link
                >
              </li>

              <template v-if="subSubItem.opened && subSubItem.items && subSubItem.items.length">
                <!-- Link to open LP level page -->
                <li :key="subSubItem.id" class="text-left">
                  <router-link
                    :to="subSubItem.link"
                    class="pointer second-sub-sub-menu"
                    @click.native="closeSidebar"
                    :style="{ color: style.menuTextColor }"
                    >> {{ getLearningPageLevelTitle(subSubItem.title) }}</router-link
                  >
                </li>
                <template>
                  <li class="text-left" :key="'dropdown-' + subSubSubItem.id" v-for="subSubSubItem in subSubItem.items">
                    <router-link
                      :to="subSubSubItem.link"
                      class="pointer second-sub-sub-menu"
                      @click.native="closeSidebar"
                      :style="{ color: style.menuTextColor }"
                      >{{ subSubSubItem.title }}</router-link
                    >
                  </li>
                </template>
              </template>
            </template>
          </template>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  created() {
    let style = document.createElement('style');
    style.id = 'sidebar-styles';
    style.type = 'text/css';
    style.appendChild(
      document.createTextNode(`
        .sidebar-header-img {
          background-color: ${this.style.menuBackgroundColor},
        }
        .sidebar-nav li:not(.disabled):hover, .sidebar-nav li:not(.disabled):hover a, .sidebar-nav li:active, .sidebar-nav li a.active {
          background-color: ${this.style.menuHoverBackgroundColor} !important;
          color: ${this.style.menuHoverTextColor} !important;
        }
         .sidebar-nav li:active i, .sidebar-nav li:hover i {
          color: ${this.style.menuHoverTextColor} !important;
          width: 20px;
         }
      `)
    );
    this.styleNode = style.childNodes[0];
    document.head.appendChild(style);
  },
  computed: {
    ...mapGetters('settings', ['company', 'sidebarShown', 'style']),
    ...mapGetters('dashboard', ['dashboardData', 'menu'])
  },
  methods: {
    getLearningPageLevelTitle(lpTitle) {
      return this.$t('link.open', { lpTitle });
    },
    closeSidebar() {
      if (this.sidebarShown) {
        this.$store.dispatch('settings/closeSidebar');
      }
      document.getElementById('menu-button').focus();
    },
    navigate(item) {
      this.closeSidebar();

      switch (item.type) {
        case 1:
          if (item.formId) {
            this.$http.get(`dashboard/companypages/${item.sectionId}/items/${item.id}`).then(
              (response) => {
                if (response.data && (response.data.description || response.data.htmlContent)) {
                  return this.$router.push({
                    name: 'companyPageContent',
                    params: { overview: item.sectionId, id: item.id }
                  });
                }

                this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
              },
              (err) => {
                this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
              }
            );
          } else {
            if (item.sectionId) {
              this.$router.push({ name: 'companyPageContent', params: { overview: item.sectionId, id: item.id } });
            } else {
              this.$router.push({ name: 'companyPageOverview', params: { id: item.id } });
            }
          }
          break;
        case 3:
          this.$router.push({ name: 'introduction', params: { id: item.id } });
          break;
        case 2:
        case 6:
        case 7:
        case 8:
          const scrollToElement = () => {
            const el = document.getElementById(item.title.toLowerCase().replace(/ /gi, '-'));
            if (el) {
              el.scrollIntoView();
            }
          };
          if (this.$route.name !== 'dashboard') {
            this.$router.push({ name: 'dashboard' }, () => {
              setTimeout(() => {
                scrollToElement();
              }, 200);
            });
          } else {
            scrollToElement();
          }
          break;
        case 9: {
          this.$router.push({ name: 'legacyForm', params: { id: item.id } });
          break;
        }
      }
    },
    toggleMenuDropdown(item) {
      this.$store.dispatch('dashboard/toggleMenuDropdown', item.id);
    },
    toggleSubMenuDropdown(item, subItem) {
      this.$store.dispatch('dashboard/toggleSubMenuDropdown', {
        itemId: item.id,
        subItemId: subItem.id
      });
    },
    toggleSubSubMenuDropdown(item, subItem, subSubItem) {
      this.$store.dispatch('dashboard/toggleSubSubMenuDropdown', {
        itemId: item.id,
        subItemId: subItem.id,
        subSubItemId: subSubItem.id
      });
    }
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('settings/closeSidebar');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';
$width: 300px;

.sidebar-nav li.disabled {
  cursor: not-allowed;
  a {
    color: #c9c9c9;
    pointer-events: none;
  }
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

.sidebar-header-img {
  position: absolute;
  top: 0;
  height: 38px;
  width: $width;
}

.sidebar-header-img img {
  max-height: 30px;
  max-width: 120px;
  position: fixed;
  top: 12px;
  width: $width - 50;
  left: 12px;
  width: auto;
}

#sidebar-wrapper {
  z-index: 1000;
  left: $width;
  padding: 0;
  position: fixed;
  width: 0;
  top: 0;
  height: 100%;
  margin-left: -$width;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-box-shadow: 2px 0 9px -2px #c9c9c9;
  box-shadow: 2px 0 9px -2px #c9c9c9;
}

#sidebar-wrapper {
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-color: darkgray rgba(255, 255, 255, 0.5);
  scrollbar-width: thin;
}

// #sidebar-wrapper::-webkit-scrollbar {
//   display: none;
// }

#sidebar-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

#sidebar-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: darkgray;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

#wrapper.toggled #sidebar-wrapper {
  width: $width;
}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/

.sidebar-nav {
  position: absolute;
  top: 64px;
  width: $width;
  margin: 0;
  padding: 0;
  padding-bottom: 12px;
  list-style: none;

  @media (max-width: 576px) {
    top: 46px;
  }
}

.sidebar-nav li {
  position: relative;
  line-height: 28px;
  display: inline-block;
  width: 100%;

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .submenu-link {
    background: none;
    border: none;
  }
}

.sidebar-nav li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  background-color: #1c1c1c;
  -webkit-transition: width 0.1s ease-in;
  -moz-transition: width 0.1s ease-in;
  -ms-transition: width 0.1s ease-in;
  transition: width 0.1s ease-in;
}
.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
  width: 100%;
}

.sidebar-nav li a {
  display: block;
  color: black;
  text-decoration: none;
  padding: 12px 30px 10px 40px;

  i.material-icons {
    position: absolute;
    font-size: var(--title-size);
    top: 50%;
    transform: translateY(-50%);
    &:last-of-type {
      right: 12px;
    }

    &:first-of-type {
      left: 12px;
    }
  }
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: var(--title-size);
  line-height: 44px;
}
.sidebar-nav .dropdown-menu {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #222;
  box-shadow: none;
}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
  position: fixed;
  top: 20px;
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all 0.35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.73, 1, 0.28, 0.08);
}
.hamburger.is-open .hamb-middle {
  display: none;
}
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.73, 1, 0.28, 0.08);
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}

.sidebar-nav li a.sub-menu {
  padding-right: 2px;
  padding-left: 40px;
}
.sidebar-nav li a.second-sub-menu {
  padding-left: 55px;
}
.sidebar-nav li a.second-sub-sub-menu {
  padding-left: 70px;
}

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.8);
  z-index: 1;
}
</style>
